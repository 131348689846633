<template>
  <div>
    <v-icon color="orange"
            small
            class="mr-2"
            @click="updateData"
    >mdi-pencil
    </v-icon>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: ['darkmode', "item", "params"],
  data() {
    return {
      validPassword: true,
      user: {
        _id: null,
        user_name: null,
        password: null
      },
      dialogListUser: false,
      loading: false,
      dialogChangePassword: false,
      userChild: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null
      }
    };
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    async updateData() {
      await this.fetchCurrentVipPercentageData(this.item);
      this.fetchPopUp(true);
    },

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_vipPercentage", ["fetchPopUp", "fetchCurrentVipPercentageData", "deleteData", "fetchGetAllPayOut",]),
  },
};
</script>
